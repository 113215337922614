import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../../assets/Back.svg";
import UploadScreenSection from "./UploadScreenSection";
import { api, helper, local } from "services";

const UploadScreen = () => {
  const history = useHistory();
  const [config, setConfig] = useState({});

  let fields = local.getItem("application")?.documents;

  useEffect(() => {
    getConfigSchemeByFlowId();
  }, [JSON.stringify(config)]);

  const getConfigSchemeByFlowId = async () => {
    let result = await api.transaction({
      SERVICEID: helper.config?.["CUSTOMERGETDOCUMENTSCONFIG"]?.id,
      flowId: local.getItem("flowId"),
      name: "Customer get document Config"
    });
    if (
      result.err == 200 &&
      result.data.errorCode == 0 &&
      result.data.data.schema
    ) {
      // setConfig(result.data.data.schema);
      let _config = {};
      for (let item of result?.data?.data?.schema?.children || []) {
        _config[item.code] = item;
      }
      setConfig(_config);
    }
  };

  return (
    <div className="fixed z-10 left-0 right-0 md:max-w-lg mx-auto mt-3">
      <div className="w-24 ml-3.5">
        <button
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={Icon} alt="IconBack" />
        </button>
      </div>
      <h2 className="font-medium px-5 ">6. Upload chứng từ</h2>
      <button className="w-full">
        <div
          className="scrollbar mt-4 px-5"
          style={{ height: "calc(100vh - 96px)" }}
          id="style-1"
        >
          {Object.keys(fields?.othersDocuments)?.map((field, index) => {
            let data = fields.othersDocuments[field];
            return (
              <UploadScreenSection
                section={field}
                data={data}
                key={index}
                config={config[field]}
              />
            );
          })}
        </div>
      </button>
    </div>
  );
};

export default UploadScreen;
