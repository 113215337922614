import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    profile: null,
    ekycData: null,
    images: {
        front: null,
        back: null,
        face: null
    },
    uploadedImages: {
        front: null,
        back: null,
        face: null
    }
}

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setImage: (state, action) => {
            const { value, side } = action.payload
            state.images[side] = value
        },
        setUploadedImage: (state, action) => {
            const { value, side } = action.payload
            state.uploadedImages[side] = value
        },
        setEkycData: (state, action) => {
            state.ekycData = action.payload
        },
    }
})

export const selectProfile = (state) => state.config.profile
export const { setProfile, setImage, setUploadedImage, setEkycData } = configSlice.actions

export default configSlice.reducer
