let CONFIG = window?.configEnv || {};

const config = {
  host: CONFIG.HOST, // 'http://localhost:1338',
  clientIdSDK: CONFIG.CLIENTID,
  client_secret: CONFIG.CLIENTSECRET,
  hostOrigin: CONFIG.HOSTORIGIN,
  hostReferer: CONFIG.HOSTREFERER,
  baseApiSDK: CONFIG.BASEAPISDK,
  apiPath: CONFIG.APIPATH,
  GOOGLE_MAP_API_KEY: CONFIG.GOOGLE_MAP_API_KEY
};
export default config;
