import moment from "moment";
import config from "./config";
import JpgIcon from "assets/jpg_icon.png";
import attachment_icon from "assets/attachment_icon.png";
import doc_icon from "assets/doc_icon.png";
import mp4_icon from "assets/mp4_icon.png";
import png_icon from "assets/png_icon.png";
import pp_icon from "assets/pp_icon.png";
import xlsx_icon from "assets/xlsx_icon.png";
import pdf_icon from "assets/pdf_icon.png";

const constant = {};

constant.SERVICEID = {
  APPLICATION_DETAIL: "64880fe3ab984c72ba592d8a",
};

constant.STEPID_PATH = {
  SE_Input_information: "/eSign_signing_login",
  SE_Review: "/eSign_view_contract",
  SE_Input_OTP: "/eSign_verify_es",
  SE_eKYC: "/eSign_start_confirm_infor",
  SE_Finish: "/eSign_view_contract",
  SE_Go_to_counter: "/eSign_view_contract",
  SE_View_Documents: "/eSign_view_documents",
};

constant.IDENTITY_TYPE = {
  CCCD: "Căn cước công dân",
  CMND: "Chứng minh nhân dân",
  CMQD: "Chứng minh Quân đội",
  PASSPORT: "Hộ chiếu"
};

constant.IDENTITY = {
  CCCD: "Số CCCD",
  CMND: "Số CMND",
  CMQD: "Số CM Quân đội",
  PASSPORT: "Hộ chiếu"
}

constant.STEP_NO = {
  SE_Input_information: 1,
  SE_Review: 2,
  SE_Input_OTP: 4,
  SE_eKYC: 3,
  SE_Finish: 5,
  SE_Go_to_counter: 3.5,
};

// constant.TRUEID_CONFIG = {
//   clientId: config.clientIdSDK,
//   clientSecret: config.client_secret,
//   configHeader: "{\"header1\":\"header1_value\", \"header2\":\"header2_value\"}",
//   stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
//   domain: config.baseApiSDK,
//   configEndpoint: {
//     front: "/ekyc/v1.2/id/verify/front",
//     back: "/ekyc/v1.2/id/verify/back",
//     selfie: "/ekyc/v1.2/selfie/verify",
//     complete: "/ekyc/v1.2/complete",
//     nfcqrverify: "/ekyc/v2.0/nfcqr/verify",
//     nfcrar: "/ekyc/v1.2/nfcqr/upload",
//     ocr: "/ekyc/v1.2/id/ocr",
//     nfcbshield: "/ekyc/v1.2/nfcqr/upload",
//     createrequest: "/ekyc/v1.2/request/create",
//     accesstoken: "/auth/v1/oauth/accessToken"
//   },
//   titleColor: "#091E42CC",//80%
//   subTitleColor: "#091E4299",//60%
//   closeColor: "#091E42CC",//80%
//   buttonCaptureColor: "#f04544",
//   titleButtonCaptureColor: "#ffffff",
//   backgroundColor: "#ffffff",
//   requestId: "",
//   accessToken: ""
// }

constant.TRUEID_CONFIG = {
  domain: config.baseApiSDK,
  configEndpoint: {
    front: "/ekyc/v1.2/id/verify/front",
    back: "/ekyc/v1.2/id/verify/back",
    selfie: "/ekyc/v1.2/face/liveness",
    complete: "/ekyc/v1.2/complete",
    nfcqrverify: "/ekyc/v2.0/nfcqr/verify",
    nfcrar: "/ekyc/v1.2/nfcqr/upload",
    ocr: "/ekyc/v1.2/id/ocr",
    nfcbshield: "/ekyc/v1.2/nfcqr/upload",
    createrequest: "/ekyc/v1.2/request/create",
    accesstoken: "/auth/v1/oauth/accessToken"
  },
  clientId: config.clientIdSDK,
  clientSecret: config.client_secret,
  configHeader: "{\"header1\":\"header1_value\", \"header2\":\"header2_value\"}",
  stepVerification: ["FRONT", "BACK", "SELFIE"],
  titleColor: "#091E42CC",//80%
  subTitleColor: "#091E4299",//60%
  closeColor: "#091E42CC",//80%
  buttonCaptureColor: "#f04544",
  titleButtonCaptureColor: "#ffffff",
  backgroundColor: "#ffffff",
  requestId: "",
  accessToken: ""
}


constant.style = {
  LFVN_THEME: "#E7252B",
  DISABLED: "#D6D6D6"
}

constant.FILE_TYPE_IMAGE = {
  jpg: JpgIcon,
  others: attachment_icon,
  docx: doc_icon,
  xlsx: xlsx_icon,
  pdf: pdf_icon,
  pptx: pp_icon,
  png: png_icon,
  mp4: mp4_icon,
};

constant.STEP_MAPPING_CONTENT = (step, ekycTimestamp) => ({
  SE_Go_to_counter: "Kết quả eKYC không hợp lệ. Quý khách vui lòng qua chi nhánh để hoàn thành thủ tục ký",
  SE_Input_OTP: `Quý khách đã thực hiện thành công quá trình xác thực thông tin vào ${moment(ekycTimestamp).format("HH:mm:ss")} ngày ${moment(ekycTimestamp).format("DD-MM-YYYY")}. Vui lòng không thoát khỏi app và tiếp tục tiến trình ký Hợp đồng điện tử`,
  SE_eKYC: "Kết quả eKYC không hợp lệ. Quý khách vui lòng thực hiện lại.",
  pending: "Hệ thống đang bận vui lòng thử lại",
}[step])


constant.decodeDocumentName = (name) => {
  try {
    const binaryString = atob(name);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder("utf-8");
    const decodedString = decoder.decode(bytes);
    return decodedString.substring(0, 20) + "...";
  } catch (e) {
    return null;
  }
}


export default constant;
