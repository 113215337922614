const file = {}
file.base64ToArrayBuffer = async (base64) => {
    const res = await fetch(base64)
    return await res.blob()
}
/**
 * 
 * @param {*} image1 image base64 or URLObject
 * @param {*} image2 image base64 or URLObject
 */
file.mergeImage = async (images = [], outputFormat = "blob") => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img1 = new Image();
        const img2 = new Image();
        img1.src = images[0]
        img2.src = images[1]
        Promise.all([
            new Promise((resolve) => (img1.onload = resolve)),
            new Promise((resolve) => (img2.onload = resolve)),
        ]).then(async () => {
            const scale = img1.width / img2.width;
            const img2ScaledHeight = img2.height * scale;

            canvas.width = img1.width;
            canvas.height = img1.height + img2ScaledHeight;

            ctx.drawImage(img1, 0, 0);
            ctx.drawImage(img2, 0, img1.height, img1.width, img2ScaledHeight);
            if(outputFormat === "base64") return resolve(canvas.toDataURL("image/jpeg"))
            const imageBlob = await file.base64ToArrayBuffer(
                canvas.toDataURL("image/jpeg")
            );
            const mergedImage = URL.createObjectURL(imageBlob)
            resolve(mergedImage)
            canvas.remove();
        }).catch((err) => reject(err))
    })
}


export default file