import { configureStore } from '@reduxjs/toolkit'
import configslice from './configslice'

const store = configureStore({
  reducer: {
    config: configslice
  },
})

export default store
