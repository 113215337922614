import React, { useState } from "react";
import Icon from "assets/Vector_3.svg";
import UploadScreenFolder from "./UploadScreenFolder";

const UploadScreenSection = (props) => {
  const [isShow, setIsShow] = useState(true);

  return (
    <div>
      <div
        className={`flex justify-between mb-3 mt-3 border-bottom-1 `}
        onClick={() => setIsShow(!isShow)}
      >
        <p style={{ textAlign: "left" }}>
          <b>{props?.config?.markName}</b>
        </p>
        <p className="mt-2">
          <img
            src={Icon}
            alt=""
            style={{
              transform: isShow ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s",
            }}
          />
        </p>
      </div>
      {props.data && (
        <div style={{ display: isShow ? "block" : "none", flexWrap: "wrap" }}>
          {Object.values(props.data).map((folder, index) => (
            <UploadScreenFolder key={index} stt={index} data={folder} />
          ))}
        </div>
      )}
      <hr />
    </div>
  );
};

export default UploadScreenSection;
