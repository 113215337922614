const trueIdLang = {
  "languageEN": {
    "trueID_back_side_message": "Place your back side ID in back of the camera. Make sure all data is properly visible",
    "trueID_back_side_photo": "Id card back side",
    "trueID_blacklisted": "Blacklisted face",
    "trueID_camera_error": "Camera error",
    "trueID_card_in_image_too_small": "ID card in image too small",
    "trueID_CCCD": "CCCD",
    "trueID_check_camera_permission": "Check camera permisson",
    "trueID_CMND": "CMND",
    "trueID_dob": "Day of birth",
    "trueID_duplicated_face": "Duplicated face",
    "trueID_duplicated_id": "Duplicated ID",
    "trueID_error": "Error!",
    "trueID_face_not_matched": "Face not matched",
    "trueID_female": "Female",
    "trueID_footer": "Power by trueID",
    "trueID_front_side_message": "Place your front side ID in front of the camera. Make sure all data is properly visible",
    "trueID_front_side_photo": "Id card front side",
    "trueID_scan_qr": "Quét mã QR trên CCCD",
    "trueID_scan_qr_message": "Canh vừa mã QR vào 4 góc khung hình và thử dịch chuyển camera để quét mã",
    "trueID_full_name": "Full name",
    "trueID_gender": "Gender",
    "trueID_given_date": "Given date",
    "trueID_go_back": "Go back",
    "trueID_id_number": "ID number",
    "trueID_id_type": "ID tyoe",
    "trueID_image_blurry": "Image blurry",
    "trueID_image_error": "Image error!",
    "trueID_image_overexposed": "Image overexposed",
    "trueID_image_too_dark": "Image too dark",
    "trueID_image_too_small": "Image too small",
    "trueID_input_image_is_invalid": "Input image is invalid",
    "trueID_invalid_id_template": "Invalid ID template",
    "trueID_invalid_id_text_fields": "Invalid ID text fields",
    "trueID_invalid_selfie": "Invalid selfie",
    "trueID_male": "Male",
    "trueID_missing_corner": "Missing corner",
    "trueID_network_error": "Network error",
    "trueID_obscured": "Image is obscured",
    "trueID_ocr_title": "OCR INFORMATION",
    "trueID_permanent_address": "Permanent address",
    "trueID_place_of_issue": "Place of issue",
    "trueID_retake_photo": "Retake photo",
    "trueID_selfie_message": "Your face into the Oval",
    "trueID_selfie_photo": "TAKE PHOTO",
    "trueID_server_not_found": "Server not found",
    "trueID_spam_image": "Spam image",
    "trueID_submit": "Submit",
    "trueID_take_photo": "Take a photo",
    "trueID_unknown": "unknown",
    "trueID_use_this_photo": "Use this photo",
    "trueID_liveness_confirmed": "Liveness confirmed",
    "Understand": "Understand",
    "trueID_feedback_center_face": "Giữ khuôn mặt nhìn thẳng",
    "trueID_feedback_face_not_found": "Đưa khuôn mặt vào sát khung hình",
    "trueID_feedback_move_phone_away": "Di chuyển ra xa",
    "trueID_feedback_move_away_web": "Di chuyển ra xa",
    "trueID_feedback_move_phone_closer": "Di chuyển lại gần",
    "trueID_feedback_move_phone_to_eye_level": "Đưa camera ngang tầm mắt",
    "trueID_feedback_move_to_eye_level_web": "Đưa camera ngang tầm mắt",
    "trueID_feedback_face_not_looking_straight_ahead": "Giữ khuôn mặt nhìn thẳng",
    "trueID_feedback_face_not_upright": "Giữ tư thế đầu thẳng",
    "trueID_feedback_face_not_upright_mobile": "Giữ tư thế đầu thẳng",
    "trueID_feedback_hold_steady": "Giữ cố định",
    "trueID_feedback_move_web_closer": "Di chuyển lại gần",
    "trueID_feedback_move_web_even_closer": "Gần hơn nữa",
    "trueID_feedback_use_even_lighting": "Canh chỉnh ánh sáng đều khuôn mặt"
  },
  "languageVI": {
    "trueID_back_side_message": "Di chuyển camera để thấy rõ mặt sau CMND/CCCD. Hãy chắc chắn rằng tất cả thông tin đều rõ ràng!",
    "trueID_back_side_photo": "Chụp ảnh CMND/CCCD mặt sau",
    "trueID_blacklisted": "Danh sách đen",
    "trueID_camera_error": "Không thể truy cập máy ảnh!",
    "trueID_card_in_image_too_small": "ID trong hình ảnh quá nhỏ",
    "trueID_CCCD": "CCCD",
    "trueID_check_camera_permission": "Vui lòng kiểm tra sự cho phép của máy ảnh!",
    "trueID_CMND": "CMND",
    "trueID_dob": "Ngày sinh",
    "trueID_duplicated_face": "Khuôn mặt trùng lặp",
    "trueID_duplicated_id": "ID trùng lặp",
    "trueID_error": "Lỗi!",
    "trueID_face_not_matched": "Mặt không khớp",
    "trueID_obscured": "Ảnh chụp bị che khuất",
    "trueID_female": "Nữ",
    "trueID_footer": "Power by trueID",
    "trueID_front_side_message": "Di chuyển camera để thấy rõ mặt trước CMND/CCCD. Hãy chắc chắn rằng tất cả thông tin đều rõ ràng!",
    "trueID_front_side_photo": "Chụp ảnh CMND/CCCD mặt trước",
    "trueID_scan_qr": "Quét mã QR trên CCCD",
    "trueID_scan_qr_message": "Canh vừa mã QR vào 4 góc khung hình và thử dịch chuyển camera để quét mã",
    "trueID_full_name": "Họ và tên",
    "trueID_gender": "Giới tính",
    "trueID_given_date": "Ngày cấp",
    "trueID_go_back": "Quay lại",
    "trueID_id_number": "Số ID",
    "trueID_id_type": "Loại ID",
    "trueID_image_blurry": "Hình ảnh mờ",
    "trueID_image_error": "Hình ảnh lỗi!",
    "trueID_image_overexposed": "Hình ảnh chói sáng",
    "trueID_image_too_dark": "Hình ảnh quá tối",
    "trueID_image_too_small": "Hình ảnh quá nhỏ",
    "trueID_input_image_is_invalid": "Hình ảnh đầu vào không hợp lệ",
    "trueID_invalid_id_template": "Mẫu ID không hợp lệ",
    "trueID_invalid_id_text_fields": "Các trường thông tin ID không hợp lệ",
    "trueID_invalid_selfie": "Ảnh selfie không hợp lệ",
    "trueID_male": "Nam",
    "trueID_missing_corner": "Hình ảnh ID mất góc",
    "trueID_network_error": "Không có kết nối mạng.",
    "trueID_ocr_title": "THÔNG TIN OCR",
    "trueID_permanent_address": "Địa chỉ thường trú",
    "trueID_place_of_issue": "Nơi cấp",
    "trueID_retake_photo": "Chụp lại",
    "trueID_selfie_message": "Vui lòng giữ khuôn mặt của bạn trong khung",
    "trueID_selfie_photo": "Xác thực khuôn mặt",
    "trueID_server_not_found": "Không tìm thấy máy chủ.",
    "trueID_spam_image": "Ảnh spam",
    "trueID_submit": "Hoàn thành",
    "trueID_take_photo": "Chụp ảnh",
    "trueID_unknown": "Không rõ",
    "trueID_use_this_photo": "Sử dụng hình này",
    "trueID_liveness_confirmed": "Thông tin được xác nhận",
    "Understand": "Đã hiểu",
    "trueID_feedback_center_face": "Giữ khuôn mặt nhìn thẳng",
    "trueID_feedback_face_not_found": "Đưa khuôn mặt vào sát khung hình",
    "trueID_feedback_move_phone_away": "Di chuyển ra xa",
    "trueID_feedback_move_away_web": "Di chuyển ra xa",
    "trueID_feedback_move_phone_closer": "Di chuyển lại gần",
    "trueID_feedback_move_phone_to_eye_level": "Đưa camera ngang tầm mắt",
    "trueID_feedback_move_to_eye_level_web": "Đưa camera ngang tầm mắt",
    "trueID_feedback_face_not_looking_straight_ahead": "Giữ khuôn mặt nhìn thẳng",
    "trueID_feedback_face_not_upright": "Giữ tư thế đầu thẳng",
    "trueID_feedback_face_not_upright_mobile": "Giữ tư thế đầu thẳng",
    "trueID_feedback_hold_steady": "Giữ cố định",
    "trueID_feedback_move_web_closer": "Di chuyển lại gần",
    "trueID_feedback_move_web_even_closer": "Gần hơn nữa",
    "trueID_feedback_use_even_lighting": "Canh chỉnh ánh sáng đều khuôn mặt"
  }
}

export default trueIdLang