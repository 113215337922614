import React from "react";
const Header = ({ label }) => {
  return (
    <div className="absolute top-0 right-0 left-0 h-12 z-10 px-4 bg-white">
      <div className="text-center text-neutral-700 text-xl font-medium font-['SF Pro Display'] leading-relaxed">
        {label || ""}
      </div>
    </div>
  );
};

export default Header;
