import React, { useEffect } from "react";
import spinner from "assets/Spinner.svg";
import "./Loading.css";

const LoadingModal = () => {
  useEffect(() => {
    const modalLoading = document.getElementById("modal-loading");
    if (modalLoading) modalLoading.classList.add("show");
    return () => {
      if (modalLoading) modalLoading.classList.remove("show");
    };
  }, []);
  return (
    <div
      className="fixed top-0 bottom-0 md:max-w-lg w-full z-20 flex items-center justify-center px-8"
      style={{ background: "rgba(0,0,0,0.3)" }}
    >
      <div className="modal" id="modal-loading" data-backdrop="static">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="flex justify-center items-center">
                <img src={spinner} alt={"loading"} height={70} width={70} />
              </div>
              <span
                className="font-['SF Pro Display'] text-center"
                style={{ color: "white" }}
              >
                Loading, please wait...
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
